import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

// 使用环境变量或默认值
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://www.createlab.com.cn/api';

ReactDOM.render(
  <React.StrictMode>
    <App API_BASE_URL={API_BASE_URL} />
  </React.StrictMode>,
  document.getElementById('root')
);