import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react'
import { motion } from 'framer-motion'
import { Upload, FileImage, Download, Loader2, Briefcase, Heart, Users, Lightbulb, Palette } from 'lucide-react'
import { Button, LinearProgress, Card, CardContent, Dialog, DialogContent, DialogTitle, Snackbar } from '@mui/material'
import html2canvas from 'html2canvas'
import '../FaceInsightDemo.css'
import QRCodeSVG from '../assets/QR.svg';
import imageCompression from 'browser-image-compression';

const analysisTasks = [
  "正在处理图像...",
  "请耐心等待...",
  "这需要约1分钟时间...",
  "马上揭示你的魅力...",
  "正在生成报告..."
]

const AnalysisSection = ({ icon: Icon, title, tags, content, children }) => {
  return (
    <Card style={{ marginBottom: '24px' }}>
      <CardContent>
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px', marginBottom: '12px' }}>
          <Icon style={{ width: '24px', height: '24px' }} />
          <h3 style={{ fontSize: '18px', fontWeight: '600' }}>{title}</h3>
        </div>
        {tags && tags.length > 0 && (
          <div style={{ display: 'flex', flexWrap: 'wrap', gap: '8px', marginBottom: '16px' }}>
            {tags.map((tag, index) => (
              <span key={index} style={{ backgroundColor: '#E5E7EB', padding: '4px 8px', borderRadius: '9999px', fontSize: '14px' }}>{tag}</span>
            ))}
          </div>
        )}
        {content && <p style={{ marginBottom: '16px' }}>{content}</p>}
        {children}
      </CardContent>
    </Card>
  )
}

function Footer() {
  return (
    <footer className="footer" style={{ 
      position: 'fixed', 
      bottom: 0, 
      left: 0, 
      right: 0, 
      background: 'black', 
      color: 'white', 
      padding: '10px', 
      textAlign: 'center',
      fontSize: '12px',
      zIndex: 1000
    }}>
      <p style={{ margin: '2px 0' }}>广州向北信息科技</p>
      <p style={{ margin: '2px 0' }}>粤ICP备2024308281号</p>
    </footer>
  );
}

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://www.createlab.com.cn/api';

export default function FaceInsightDemo() {
  const [file, setFile] = useState(null)
  const [analyzing, setAnalyzing] = useState(false)
  const [results, setResults] = useState(null)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [progress, setProgress] = useState(0)
  const [currentTask, setCurrentTask] = useState(analysisTasks[0])
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const reportRef = useRef(null)
  const [intervalId, setIntervalId] = useState(null);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
  }

  const updateProgress = () => {
    setProgress(prevProgress => {
      if (prevProgress >= 100) {
        clearInterval(intervalId)
        return 100
      }
      const newProgress = prevProgress + 10
      setCurrentTask(analysisTasks[Math.min(Math.floor(newProgress / 10), analysisTasks.length - 1)])
      return newProgress
    })
  }

  const compressImage = async (file) => {
    const options = {
      maxSizeMB: 2,
      maxWidthOrHeight: 2048,
      useWebWorker: true,
      fileType: file.type || 'image/jpeg'
    }
    try {
      return await imageCompression(file, options);
    } catch (error) {
      console.error('图片压缩失败:', error);
      return file;
    }
  }

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64String = reader.result.split(',')[1];
        resolve(base64String);
      };
      reader.onerror = (error) => reject(error);
    });
  }

  const handleAnalyze = async () => {
    let newIntervalId;
    try {
      if (!file) {
        alert('请先上传一张照片');
        return;
      }

      setAnalyzing(true);
      setProgress(0);
      setCurrentTask(analysisTasks[0]);

      newIntervalId = setInterval(updateProgress, 1000);
      setIntervalId(newIntervalId);

      const compressedFile = await compressImage(file);
      const base64Image = await convertToBase64(compressedFile);

      const response = await fetch(`${API_BASE_URL}/analyze`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          type: 'face_analysis',
          image_data: base64Image
        })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      clearInterval(newIntervalId);
      setProgress(100);
      setCurrentTask(analysisTasks[analysisTasks.length - 1]);

      setTimeout(() => {
        setAnalyzing(false);
        setResults(data.analysis);
      }, 1000);
    } catch (error) {
      console.error('分析过程中出错:', error);
      setSnackbarMessage(`分析失败：${error.message}`);
      setSnackbarOpen(true);
      clearInterval(newIntervalId);
      setAnalyzing(false);
    }
  };

  const handleDownloadReport = async () => {
    const cardElement = document.querySelector('.face-insight-card');
    if (cardElement) {
      try {
        // 暂时隐藏下载按钮
        const downloadButton = cardElement.querySelector('button');
        if (downloadButton) {
          downloadButton.style.display = 'none';
        }

        // 创建一个临时的克隆元素用于生成报告
        const tempElement = cardElement.cloneNode(true);
        document.body.appendChild(tempElement);
        tempElement.style.position = 'absolute';
        tempElement.style.left = '-9999px';
        tempElement.style.width = cardElement.offsetWidth + 'px';
        tempElement.style.height = 'auto';

        // 确保所有内容都可见
        tempElement.style.overflow = 'visible';
        tempElement.querySelectorAll('*').forEach(el => {
          el.style.overflow = 'visible';
        });

        // 特别处理二维码
        const qrCodeImg = tempElement.querySelector('img[alt="QR Code"]');
        if (qrCodeImg) {
          qrCodeImg.style.width = '100px';  // 缩小二维码尺寸
          qrCodeImg.style.height = '100px';
          qrCodeImg.style.margin = '10px 0';  // 调整上下边距
        }

        // 等待图片加载完成
        await Promise.all(Array.from(tempElement.querySelectorAll('img')).map(img => {
          if (img.complete) return Promise.resolve();
          return new Promise(resolve => { img.onload = resolve; });
        }));

        const canvas = await html2canvas(tempElement, {
          scale: 2,
          useCORS: true,
          logging: false,
          backgroundColor: '#ffffff',
          windowWidth: tempElement.scrollWidth,
          windowHeight: tempElement.scrollHeight,
          onclone: function(clonedDoc) {
            const clonedQRCode = clonedDoc.querySelector('img[alt="QR Code"]');
            if (clonedQRCode) {
              clonedQRCode.style.width = '100px';
              clonedQRCode.style.height = '100px';
              clonedQRCode.style.margin = '10px 0';
            }
          }
        });

        // 移除临时元素
        document.body.removeChild(tempElement);

        // 恢复下载按钮显示
        if (downloadButton) {
          downloadButton.style.display = '';
        }

        const imageData = canvas.toDataURL('image/png');
        
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
          // 移动设备：保存到相册
          const link = document.createElement('a');
          link.href = imageData;
          link.download = 'FaceInsight_Report.png';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          setSnackbarMessage('报告已保存到相册');
        } else {
          // 桌面设备：下载图片
          const link = document.createElement('a');
          link.href = imageData;
          link.download = 'FaceInsight_Report.png';
          link.click();
          setSnackbarMessage('报告已下载');
        }
        setSnackbarOpen(true);
      } catch (error) {
        console.error('下载报告时出错:', error);
        setSnackbarMessage('下载报告失败，请重试');
        setSnackbarOpen(true);
      }
    }
  }

  useEffect(() => {
    let isMounted = true;
    return () => {
      isMounted = false;
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [intervalId]);

  return (
    <div className="face-insight-container">
      <Card className="face-insight-card">
        <CardContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '24px', padding: '24px' }}>
          <motion.h1 
            className="gradient-title"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            style={{ 
              alignSelf: results ? 'flex-start' : 'center', // 根据结果是否存在来调整对齐方式
              width: '100%',
              textAlign: results ? 'left' : 'center' // 根据结果是否存在来调整文本对齐
            }}
          >
            FaceInsight
          </motion.h1>
          
          {!file && !results && !analyzing && (
            <div className="upload-container">
              <motion.div 
                className="upload-box"
                whileHover={{ scale: 1.02, boxShadow: "0 0 15px rgba(0,0,0,0.1)" }}
                whileTap={{ scale: 0.98 }}
                onClick={() => document.getElementById('fileInput').click()}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <div style={{ textAlign: 'center' }}>
                  <motion.div
                    animate={{ y: [0, -10, 0] }}
                    transition={{ repeat: Infinity, duration: 1.5 }}
                  >
                    <Upload style={{ width: '48px', height: '48px', color: '#9CA3AF', margin: '0 auto' }} />
                  </motion.div>
                  <p style={{ marginTop: '4px', fontSize: '14px', color: '#4B5563' }}>上传照片，解读你的魅力</p>
                </div>
                <input 
                  id="fileInput" 
                  type="file" 
                  style={{ display: 'none' }} 
                  onChange={handleFileChange} 
                  accept="image/*"
                />
              </motion.div>
              <TypewriterEffect />
            </div>
          )}
          
          {file && !results && !analyzing && (
            <div style={{ width: '100%' }}>
              <motion.div 
                style={{ position: 'relative', width: '100%', height: '256px', borderRadius: '8px', overflow: 'hidden' }}
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5 }}
              >
                <img 
                  src={URL.createObjectURL(file)} 
                  alt="Uploaded" 
                  style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                />
                <div style={{ position: 'absolute', inset: 0, backgroundColor: 'rgba(0, 0, 0, 0.4)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <FileImage style={{ width: '64px', height: '64px', color: 'white' }} />
                </div>
              </motion.div>
              <Button 
                variant="contained"
                style={{ marginTop: '16px', width: '100%', background: 'linear-gradient(to right, #8B5CF6, #EC4899)' }}
                onClick={handleAnalyze}
                disabled={analyzing}
              >
                {analyzing ? (
                  <>
                    <Loader2 style={{ marginRight: '8px', width: '16px', height: '16px', animation: 'spin 1s linear infinite' }} />
                    分析中...
                  </>
                ) : '分析照片'}
              </Button>
            </div>
          )}
          
          {analyzing && (
            <div style={{ width: '100%' }}>
              <motion.div 
                style={{ width: '300px', height: '300px', margin: '0 auto 24px', borderRadius: '8px', overflow: 'hidden', position: 'relative' }}
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5 }}
              >
                <img 
                  src={URL.createObjectURL(file)} 
                  alt="Analyzing" 
                  style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                />
                <div className="scan-animation"></div>
              </motion.div>

              <LinearProgress variant="determinate" value={progress} style={{ width: '100%', marginBottom: '16px' }} />
              <p style={{ fontSize: '14px', textAlign: 'center', color: '#4B5563', marginBottom: '16px' }}>{currentTask}</p>

              <motion.div
                style={{ display: 'flex', justifyContent: 'center' }}
                animate={{ rotate: 360 }}
                transition={{ duration: 2, repeat: Infinity, ease: "linear" }}
              >
                <Loader2 style={{ width: '48px', height: '48px', color: '#8B5CF6' }} />
              </motion.div>

              <p style={{ textAlign: 'center', color: '#4B5563', marginTop: '16px' }}>
                请稍候，我们正在深入分析您的照片。这可能需要几分钟时间。
              </p>
            </div>
          )}
          
          {results && (
            <div ref={reportRef} style={{ width: '100%', paddingBottom: '60px' }}>
              <div style={{ width: '300px', height: '300px', margin: '0 auto 24px', borderRadius: '8px', overflow: 'hidden' }}>
                <img 
                  src={URL.createObjectURL(file)} 
                  alt="Analyzed" 
                  style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                />
              </div>

              <div style={{ textAlign: 'center', marginBottom: '24px' }}>
                <span style={{ backgroundColor: '#E5E7EB', padding: '8px 16px', borderRadius: '9999px', fontSize: '18px' }}>年龄: {results.age}</span>
              </div>

              <AnalysisSection
                icon={Briefcase}
                title="擅长职业"
                tags={results.career && results.career.tags}
              >
                {results.career && results.career.professions && results.career.professions.map((prof, index) => (
                  <div key={index} style={{ marginBottom: '12px' }}>
                    <h4 style={{ fontWeight: '600' }}>{prof.name}</h4>
                    <p>{prof.reason}</p>
                  </div>
                ))}
              </AnalysisSection>

              <AnalysisSection
                icon={Heart}
                title="性格特点"
                tags={results.personality && results.personality.traits}
                content={results.personality && results.personality.description}
              />

              <AnalysisSection
                icon={Users}
                title="人际关系"
                tags={results.relationships && [results.relationships.style]}
                content={results.relationships && results.relationships.description}
              />

              <AnalysisSection
                icon={Lightbulb}
                title="生活建议"
              >
                <ul style={{ paddingLeft: '20px', listStyleType: 'disc' }}>
                  {results.life_advice && results.life_advice.map((advice, index) => (
                    <li key={index}>{advice}</li>
                  ))}
                </ul>
              </AnalysisSection>

              <AnalysisSection
                icon={Palette}
                title="个人色彩"
                tags={results.personal_color && results.personal_color.colors}
                content={results.personal_color && results.personal_color.description}
              >
                <p><strong>色彩季节：</strong> {results.personal_color && results.personal_color.season}</p>
              </AnalysisSection>

              {/* 添加二维码和网址 */}
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '32px', marginBottom: '32px' }}>
                <img src={QRCodeSVG} alt="QR Code" style={{ width: '100px', height: '100px', margin: '10px 0' }} />
                <p style={{ marginTop: '8px', fontSize: '14px', color: '#4B5563' }}>www.createlab.com.cn</p>
              </div>

              {/* 下载按钮移到二维码上方 */}
              <div style={{ textAlign: 'center', marginTop: '32px', marginBottom: '20px' }}>
                <Button
                  variant="contained"
                  style={{ background: 'linear-gradient(to right, #8B5CF6, #EC4899)' }}
                  onClick={handleDownloadReport}
                >
                  <Download style={{ width: '16px', height: '16px', marginRight: '8px' }} />
                  下载完整报告
                </Button>
              </div>

              <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
                <DialogTitle>下载完整报告</DialogTitle>
                <DialogContent>
                  <p style={{ color: '#4B5563', marginBottom: '16px' }}>您的完整报告已准备就绪。此报告包含基于您照片的详细分析和洞察。</p>
                  <Button
                    variant="contained"
                    style={{ width: '100%', background: 'linear-gradient(to right, #8B5CF6, #EC4899)' }}
                    onClick={() => console.log("下载报告...")}
                  >
                    确认下载
                  </Button>
                </DialogContent>
              </Dialog>
            </div>
          )}
        </CardContent>
      </Card>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
      <Footer />
    </div>
  )
}

// 将 lines 数组移到组件外部
const typewriterLines = [
  "你了解真实的自己吗？",
  "你的另一面是？",
  "现在",
  "用一张照片",
  "揭秘你隐藏的魅力"
];

function TypewriterEffect() {
  const [currentLine, setCurrentLine] = useState(0);
  const [currentText, setCurrentText] = useState('');

  // 使用 useMemo 来记忆 lines 数组
  const lines = useMemo(() => typewriterLines, []);

  const typeNextCharacter = useCallback(() => {
    if (currentLine >= lines.length) return;

    if (currentText === lines[currentLine]) {
      setTimeout(() => {
        setCurrentLine(prevLine => prevLine + 1);
        setCurrentText('');
      }, 1500);
    } else {
      setCurrentText(prevText => lines[currentLine].slice(0, prevText.length + 1));
    }
  }, [currentLine, currentText, lines]);

  useEffect(() => {
    let isMounted = true;
    const timeout = setTimeout(typeNextCharacter, 150);
    return () => {
      isMounted = false;
      clearTimeout(timeout);
    };
  }, [typeNextCharacter]);

  return (
    <div className="typewriter-container">
      <p className="typewriter-text">{currentText}</p>
    </div>
  );
}