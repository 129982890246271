import React from 'react';
import FaceInsightDemo from './components/FaceInsightDemo';

function App({ API_BASE_URL }) {
  const handleAnalyze = async (imageData) => {
    try {
      const response = await fetch(`${API_BASE_URL}/analyze`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          type: 'face_analysis',
          image_data: imageData,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      console.log('Analysis result:', result);
      // 处理分析结果，例如更新状态或显示结果
    } catch (error) {
      console.error('Error during analysis:', error);
      // 处理错误，例如显示错误消息给用户
    }
  };

  return (
    <div className="App">
      <FaceInsightDemo onAnalyze={handleAnalyze} API_BASE_URL={API_BASE_URL} />
    </div>
  );
}

export default App;